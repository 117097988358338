define("discourse/plugins/discourse-events/discourse/components/events-calendar-card", ["exports", "@glimmer/component", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/service", "@ember/template", "discourse/helpers/category-link", "discourse/helpers/discourse-tags", "discourse/helpers/replace-emoji", "discourse/lib/text", "discourse/lib/url", "discourse-common/helpers/d-icon", "float-kit/components/d-tooltip", "discourse/plugins/discourse-events/discourse/lib/date-utilities", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _modifier, _object, _service, _template2, _categoryLink, _discourseTags, _replaceEmoji, _text, _url, _dIcon, _dTooltip, _dateUtilities, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EventsCalendarCard extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    constructor(owner1, args1) {
      super(owner1, args1);
      this.loadCookedText();
    }
    async loadCookedText() {
      if (this.args.event.topic) {
        const cookedExcerpt1 = await (0, _text.cook)(this.args.event.topic.excerpt);
        const cookedTitle1 = await (0, _text.cook)(this.args.event.topic.title);
        this.cookedExcerpt = cookedExcerpt1;
        this.cookedTitle = cookedTitle1;
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "loadCookedText", [_object.action]))();
    get topicEventLabel() {
      return (0, _dateUtilities.eventLabel)(this.args.event.topic.event, {
        useEventTimezone: true,
        siteSettings: this.siteSettings
      });
    }
    goToTopic() {
      event?.preventDefault();
      _url.default.routeTo(this.args.event.topic.url);
    }
    static #_3 = (() => dt7948.n(this.prototype, "goToTopic", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DTooltip
          @interactive={{true}}
          @triggers={{array "click"}}
          @identifier="events-calendar-card"
          @arrow={{false}}
        >
          <:trigger>
            <div class={{@event.classes}} style={{@event.listStyle}}>
              {{#unless @event.allDay}}
                <span style={{@event.dotStyle}}>{{icon "circle"}}</span>
              {{/unless}}
              {{#if @event.time}}
                <span class="time">{{@event.time}}</span>
              {{/if}}
              {{#if @event.title}}
                <span class="title" style={{@event.titleStyle}}>{{replaceEmoji
                    @event.title
                  }}</span>
              {{/if}}
            </div>
          </:trigger>
          <:content>
            <div class="events-calendar-card" data-topic-id={{@event.topic.id}}>
              <a class="topic-link" href {{on "click" this.goToTopic}}>
                {{this.cookedTitle}}
              </a>
    
              <div class="topic-meta">
                {{categoryLink @event.topic.category}}
                {{discourseTags @event.topic mode="list"}}
              </div>
    
              <a href class="topic-event" {{on "click" this.goToTopic}}>
                {{htmlSafe this.topicEventLabel}}
              </a>
    
              <a href class="topic-excerpt" {{on "click" this.goToTopic}}>
                {{this.cookedExcerpt}}
              </a>
            </div>
          </:content>
        </DTooltip>
      
    */
    {
      "id": "QmJQOCA5",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@interactive\",\"@triggers\",\"@identifier\",\"@arrow\"],[true,[28,[32,1],[\"click\"],null],\"events-calendar-card\",false]],[[\"trigger\",\"content\"],[[[[1,\"\\n        \"],[10,0],[15,0,[30,1,[\"classes\"]]],[15,5,[30,1,[\"listStyle\"]]],[12],[1,\"\\n\"],[41,[51,[30,1,[\"allDay\"]]],[[[1,\"            \"],[10,1],[15,5,[30,1,[\"dotStyle\"]]],[12],[1,[28,[32,2],[\"circle\"],null]],[13],[1,\"\\n\"]],[]],null],[41,[30,1,[\"time\"]],[[[1,\"            \"],[10,1],[14,0,\"time\"],[12],[1,[30,1,[\"time\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,1,[\"title\"]],[[[1,\"            \"],[10,1],[14,0,\"title\"],[15,5,[30,1,[\"titleStyle\"]]],[12],[1,[28,[32,3],[[30,1,[\"title\"]]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[10,0],[14,0,\"events-calendar-card\"],[15,\"data-topic-id\",[30,1,[\"topic\",\"id\"]]],[12],[1,\"\\n          \"],[11,3],[24,0,\"topic-link\"],[24,6,\"\"],[4,[32,4],[\"click\",[30,0,[\"goToTopic\"]]],null],[12],[1,\"\\n            \"],[1,[30,0,[\"cookedTitle\"]]],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[10,0],[14,0,\"topic-meta\"],[12],[1,\"\\n            \"],[1,[28,[32,5],[[30,1,[\"topic\",\"category\"]]],null]],[1,\"\\n            \"],[1,[28,[32,6],[[30,1,[\"topic\"]]],[[\"mode\"],[\"list\"]]]],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[11,3],[24,6,\"\"],[24,0,\"topic-event\"],[4,[32,4],[\"click\",[30,0,[\"goToTopic\"]]],null],[12],[1,\"\\n            \"],[1,[28,[32,7],[[30,0,[\"topicEventLabel\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[11,3],[24,6,\"\"],[24,0,\"topic-excerpt\"],[4,[32,4],[\"click\",[30,0,[\"goToTopic\"]]],null],[12],[1,\"\\n            \"],[1,[30,0,[\"cookedExcerpt\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@event\"],false,[\"unless\",\"if\"]]",
      "moduleName": "/home/pacs/nhg00/users/plattform/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-events/discourse/components/events-calendar-card.js",
      "scope": () => [_dTooltip.default, _helper.array, _dIcon.default, _replaceEmoji.default, _modifier.on, _categoryLink.default, _discourseTags.default, _template2.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EventsCalendarCard;
});