define("discourse/plugins/discourse-events/discourse/components/add-event-controls", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/plugins/discourse-events/discourse/lib/date-utilities", "discourse/plugins/discourse-events/discourse/components/modal/add-event", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dButton, _dateUtilities, _addEvent, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AddToCalendar extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "event", [_tracking.tracked], function () {
      return this.args.event;
    }))();
    #event = (() => (dt7948.i(this, "event"), void 0))();
    get valueClasses() {
      let classes1 = "add-event";
      if (this.args.noText) {
        classes1 += " btn-primary";
      }
      return classes1;
    }
    get valueLabel() {
      return (0, _dateUtilities.eventLabel)(this.event, {
        noText: this.args.noText,
        noContainer: true,
        useEventTimezone: true,
        showRsvp: true,
        siteSettings: this.siteSettings
      });
    }
    get iconOnly() {
      return this.args.noText || this.siteSettings.events_event_label_no_text || Boolean(this.args.category && this.args.category.custom_fields.events_event_label_no_text);
    }
    showAddEvent() {
      this.modal.show(_addEvent.default, {
        model: {
          bufferedEvent: this.event,
          event: this.event,
          update: event1 => {
            this.event = event1;
            this.args.updateEvent(event1);
          }
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "showAddEvent", [_object.action]))();
    removeEvent() {
      this.event = null;
      this.args.updateEvent(null);
    }
    static #_5 = (() => dt7948.n(this.prototype, "removeEvent", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="add-event-controls">
          {{#if this.event}}
            <DButton
              @action={{this.showAddEvent}}
              class={{this.valueClasses}}
              @translatedLabel={{this.valueLabel}}
            />
            {{#unless @noText}}
              <DButton @icon="times" @action={{this.removeEvent}} class="remove" />
            {{/unless}}
          {{else}}
            {{#if @iconOnly}}
              <DButton
                @icon={{this.siteSettings.events_event_label_icon}}
                @action={{this.showAddEvent}}
                class="add-event"
                @title="add_event.btn_label"
              />
            {{else}}
              <DButton
                @icon={{this.siteSettings.events_event_label_icon}}
                @action={{this.showAddEvent}}
                class="add-event"
                @title="add_event.btn_label"
                @label="add_event.btn_label"
              />
            {{/if}}
          {{/if}}
        </div>
      
    */
    {
      "id": "LWCZGbhv",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"add-event-controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"event\"]],[[[1,\"        \"],[8,[32,0],[[16,0,[30,0,[\"valueClasses\"]]]],[[\"@action\",\"@translatedLabel\"],[[30,0,[\"showAddEvent\"]],[30,0,[\"valueLabel\"]]]],null],[1,\"\\n\"],[41,[51,[30,1]],[[[1,\"          \"],[8,[32,0],[[24,0,\"remove\"]],[[\"@icon\",\"@action\"],[\"times\",[30,0,[\"removeEvent\"]]]],null],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,2],[[[1,\"          \"],[8,[32,0],[[24,0,\"add-event\"]],[[\"@icon\",\"@action\",\"@title\"],[[30,0,[\"siteSettings\",\"events_event_label_icon\"]],[30,0,[\"showAddEvent\"]],\"add_event.btn_label\"]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,0],[[24,0,\"add-event\"]],[[\"@icon\",\"@action\",\"@title\",\"@label\"],[[30,0,[\"siteSettings\",\"events_event_label_icon\"]],[30,0,[\"showAddEvent\"]],\"add_event.btn_label\",\"add_event.btn_label\"]],null],[1,\"\\n\"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@noText\",\"@iconOnly\"],false,[\"if\",\"unless\"]]",
      "moduleName": "/home/pacs/nhg00/users/plattform/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-events/discourse/components/add-event-controls.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AddToCalendar;
});