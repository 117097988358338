define("discourse/plugins/discourse-events/discourse/components/event-video-btn", ["exports", "@glimmer/component", "discourse-common/helpers/d-icon", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EventVideoBtn extends _component.default {
    get label() {
      return _I18n.default.t("topic.event.video.label");
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <a
          href={{@video_url}}
          target="_blank"
          role="button"
          class="btn btn-primary btn-event-video"
          rel="noopener noreferrer"
        >
          {{dIcon "video"}}
          <span>{{this.label}}</span>
        </a>
      
    */
    {
      "id": "wQNWSuLX",
      "block": "[[[1,\"\\n    \"],[10,3],[15,6,[30,1]],[14,\"target\",\"_blank\"],[14,\"role\",\"button\"],[14,0,\"btn btn-primary btn-event-video\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"video\"],null]],[1,\"\\n      \"],[10,1],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@video_url\"],false,[]]",
      "moduleName": "/home/pacs/nhg00/users/plattform/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-events/discourse/components/event-video-btn.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EventVideoBtn;
});