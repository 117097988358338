define("discourse/plugins/discourse-events/discourse/components/events-provider-status", ["exports", "@glimmer/component", "@ember/string", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _string, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EventsProviderStatus extends _component.default {
    get title() {
      return _I18n.default.t(`admin.events.provider.status.${this.args.status}.title`);
    }
    get class() {
      return `status ${(0, _string.dasherize)(this.args.status)}`;
    }
    get label() {
      return _I18n.default.t(`admin.events.provider.status.${this.args.status}.label`);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="events-provider-status">
          <span class={{this.class}} title={{this.title}}></span>
          <span>{{this.label}}</span>
        </div>
      
    */
    {
      "id": "itfp5Fel",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"events-provider-status\"],[12],[1,\"\\n      \"],[10,1],[15,0,[30,0,[\"class\"]]],[15,\"title\",[30,0,[\"title\"]]],[12],[13],[1,\"\\n      \"],[10,1],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/home/pacs/nhg00/users/plattform/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-events/discourse/components/events-provider-status.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EventsProviderStatus;
});